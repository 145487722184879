/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import "./hero.scss";

interface Props {
  children?: any;
  [key:string]:any;
}


const Hero = (props: Props) => {

  const filteredProps = ()=> {
    let className = `hero r :a-ct :align-safe-center`;

    const filteredProps = { ...props };
    delete filteredProps.children;
    if (typeof filteredProps.className === "string") {
      className = filteredProps.className + " " + className;
    }
    filteredProps.className = className;
    return filteredProps;
  }
  
  return <div {...filteredProps()} ><div>{props.children}</div></div>;
};

export default Hero;
