/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useMemo, useEffect } from "react";
import ArrowDown from "../../images/svg/arrow-down.inline.svg";
import { Link } from "gatsby";

import { Config } from "../../../config";
import { scrollTo, EasingFunctions } from "../../utils/utils";

interface Props {
  parent: any;
  to: any;
  color?: string;
  [key: string]: any;
}

const baseStyle = {
  width: '3em',
  cursor: "pointer",
  position: "relative",
  height: '3em',
  borderRadius: '50%',
  background: "black"
};

const ScrollButton = (props: Props) => {

  let callback = null;
  let eventsBound = false;
  const onScroll = useMemo(() => {
    return (event: Event) => {
      if (callback) {
        callback();
      }
    }
  }, [props.parent, callback]);

  const getParent = useMemo(() => {
    return () => {
      if (props.parent) {
        return props.parent.current;
      }
      if (document.body.classList.contains('modal-active')) {
        return document.querySelector('#modal .project');
      }
      return document.scrollingElement;
    }
  }, [props.parent]);

  const getEventParent = useMemo(() => {
    return () => {
      if (props.parent) {
        return props.parent.current;
      }
      return window;
    }
  }, [props.parent]);

  useEffect(() => {

    return () => {
      if (eventsBound) {
        const eventParent = getEventParent();
        eventParent.removeEventListener('mousedown', onScroll);
        eventParent.removeEventListener('wheel', onScroll);
        eventParent.removeEventListener('DOMMouseScroll', onScroll);
        eventParent.removeEventListener('mousewheel', onScroll);
        eventParent.removeEventListener('keyup', onScroll);
        eventParent.removeEventListener('touchmove', onScroll);
      }
    };
  })
  function onClick(event: Event) {
    if (!props.to) {
      return;
    }
    if (!eventsBound) {
      eventsBound = true;
      const eventParent = getEventParent();
      eventParent.addEventListener('mousedown', onScroll);
      eventParent.addEventListener('wheel', onScroll);
      eventParent.addEventListener('DOMMouseScroll', onScroll);
      eventParent.addEventListener('mousewheel', onScroll);
      eventParent.addEventListener('keyup', onScroll);
      eventParent.addEventListener('touchmove', onScroll);
    }

    callback = scrollTo(getParent(), props.to.current.offsetTop, {
      speed: 1000,
      onFinish: (notCanceled) => {
        callback = null;
      },
      easing: EasingFunctions.easeOutCubic
    });
  }
  function filteredProps() {
    const filteredProps = { ...props };
    delete filteredProps.to;
    delete filteredProps.parent;
    delete filteredProps.style;
    delete filteredProps.color;
    return filteredProps;
  }
  let style = baseStyle;
  if (props.style) {
    style = Object.assign({}, baseStyle, props.style);
  }
  return <div onClick={onClick} className="scroll-button" style={style} {...filteredProps()}>
    <ArrowDown style={{
      stroke: props.color, margin: '0 auto',
      width: '45%',
      position: 'absolute',
      height: '45%',
      transform: 'translate(-50%, -50%)',
      top: '50%',
      left: '50%',
    }} />
  </div>;
};

ScrollButton.defaultProps = {
  color: "white",
};

export default ScrollButton;
