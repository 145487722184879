import React, { useRef } from "react";
import SEO from "../../components/seo/seo";
import { PageParams, PaginationPageContext } from "../../../types";
import Hero from "../../components/hero/hero";
import Section from "../../components/air-section/air-section";

import "./portfolio.scss";
import PortfolioList from "../../components/portfolio-list/portfolio-list";
import ScrollButton from "../../components/scroll-button/scroll-button";

const PortfolioPage = (params: PageParams<PaginationPageContext>) => {
  const language = params.pageContext.language;
  const items = params.pageContext.nodes;
  const allItems = params.pageContext.allNodes;
  const scrollRef = useRef();
  return (
    <>
      <SEO title="Realizacje" lang={language} />
      <Hero>
        <div className="container-xs header-padding">
          <h1>Nasze Realizacje</h1>
          <div className="header-subline">
            <h2 className="subtitle">
              Wierzymy we wszystkie projekty cyfrowe, które tworzymy dla
              klientów
            </h2>
          </div>
          <h4>
            Realizując strony internetowe, sklepy e-commerce, kampanie
            marketingowe, aplikacje mobilne lub desktopowe - zdobyliśmy
            doświadczenie, które skutecznie wykorzystujemy w&nbsp;komercyjnych
            projektach.
          </h4>
        </div>
        <ScrollButton style={{
          position: "absolute",
          left: "50%",
          bottom: "5%",
          transform: "translateX(-50%)"
        }} to={scrollRef} />
      </Hero>
      <Section className="container" ref={scrollRef}>
        <PortfolioList items={items}></PortfolioList>
      </Section>
    </>
  );
};

export default PortfolioPage;
